
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/unit.js';
            vueApps['app/unit.js'] = App0;
        

            import App1 from './app/search.js';
            vueApps['app/search.js'] = App1;
        

            import App2 from './app/navbar.js';
            vueApps['app/navbar.js'] = App2;
        

            import App3 from './app/dummy.js';
            vueApps['app/dummy.js'] = App3;
        

            import App4 from './app/blog.js';
            vueApps['app/blog.js'] = App4;
        

            import App5 from './app/SimilarUnits.js';
            vueApps['app/SimilarUnits.js'] = App5;
        

            import App6 from './app/UnitsOfTheDay.js';
            vueApps['app/UnitsOfTheDay.js'] = App6;
        

            import App7 from './app/LastViewedUnits.js';
            vueApps['app/LastViewedUnits.js'] = App7;
        

            import App8 from './app/Angebote.js';
            vueApps['app/Angebote.js'] = App8;
        

            import App9 from './app/search-mit-sauna.js';
            vueApps['app/search-mit-sauna.js'] = App9;
        

            import App10 from './app/search-mit-hund.js';
            vueApps['app/search-mit-hund.js'] = App10;
        

            import App11 from './app/searchferienhauser.js';
            vueApps['app/searchferienhauser.js'] = App11;
        

            import App12 from './app/searchferienwohnungen.js';
            vueApps['app/searchferienwohnungen.js'] = App12;
        

            import App13 from './app/searchlangzeit-miete.js';
            vueApps['app/searchlangzeit-miete.js'] = App13;
        

            import App14 from './app/search-mit-kamin.js';
            vueApps['app/search-mit-kamin.js'] = App14;
        

            import App15 from './app/searchworkation.js';
            vueApps['app/searchworkation.js'] = App15;
        

            import App16 from './app/searchmonteure.js';
            vueApps['app/searchmonteure.js'] = App16;
        

            import App17 from './app/searchferienunterkunfte.js';
            vueApps['app/searchferienunterkunfte.js'] = App17;
        

            import App18 from './app/searchFerienwohnungen.js';
            vueApps['app/searchFerienwohnungen.js'] = App18;
        

            import App19 from './app/searchsiedlung.js';
            vueApps['app/searchsiedlung.js'] = App19;
        

            import App20 from './app/searchzentrum.js';
            vueApps['app/searchzentrum.js'] = App20;
        

            import App21 from './app/searchhferienhaus-6-person.js';
            vueApps['app/searchhferienhaus-6-person.js'] = App21;
        

            import App22 from './app/searchjuist-loog.js';
            vueApps['app/searchjuist-loog.js'] = App22;
        

            import App23 from './app/searchostdorf.js';
            vueApps['app/searchostdorf.js'] = App23;
        

            import App24 from './app/searchbillstrabe.js';
            vueApps['app/searchbillstrabe.js'] = App24;
        

            import App25 from './app/searchFerienunterkunfte.js';
            vueApps['app/searchFerienunterkunfte.js'] = App25;
        

            import App26 from './app/searchpersonen.js';
            vueApps['app/searchpersonen.js'] = App26;
        

            import App27 from './app/search-mit-meerblick.js';
            vueApps['app/search-mit-meerblick.js'] = App27;
        

            import App28 from './app/langzeit-miete.js';
            vueApps['app/langzeit-miete.js'] = App28;
        

        
                import Comp29 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp29);
            

                import Comp30 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp30);
            

                import Comp31 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp31);
            

                import Comp32 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp32);
            

                import Comp33 from './comp/PopupDialog.vue';
                Vue.component('voffice-popup-dlg',Comp33);
            

                import Comp34 from './videoiframe.vue';
                Vue.component('video-iframe',Comp34);
            

                import Comp35 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp35);
            

                import Comp36 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp36);
            

                import Comp37 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp37);
            

                import Comp38 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp38);
            

                import Comp39 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp39);
            

                import Comp40 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp40);
            

                import Comp41 from './comp/DateRangeExt.vue';
                Vue.component('voffice-daterange-ext',Comp41);
            

                import Comp42 from './comp/NewsletterSubsription.js';
                Vue.component('voffice-newsletter-subscription',Comp42);
            

                import Comp43 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp43);
            

                import Comp44 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp44);
            

                import Comp45 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp45);
            

                import Comp46 from './comp/Accordion.vue';
                Vue.component('voffice-accordion',Comp46);
            

                import Comp47 from './imageCarousel.vue';
                Vue.component('img-carousel',Comp47);
            

                import Comp48 from './comp/openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp48);
            

                import Comp49 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp49);
            

                import Comp50 from './comp/IncreaseNumber.vue';
                Vue.component('increase-number',Comp50);
            

                import Comp51 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp51);
            

                import Comp52 from './offershelp.vue';
                Vue.component('offer-help',Comp52);
            

                import Comp53 from './pricehelp.vue';
                Vue.component('price-help',Comp53);
            

                import Comp54 from './comp/SearchFilterTokens-v2.vue';
                Vue.component('search-filter-tokens-v2',Comp54);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    